import React, { useEffect } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    
    return (<Layout>
        <SEO title="About" />
        
        <div className="row main-content container" id="mainContent">
        <div className="row main-content__box">
        <h2>ABOUT</h2>
        
        <p>My name is Katerina Metaxopoulou but most people call me Kat for short. I was born in Greece but I have been living in the UK for over 10 years.</p>
        
        <p>I am a web developer with a passion for creating websites that are user friendly. My journey into web development started when I was 14 years old when I made my first website and since then I knew it was my passion!</p>
        
        <p>Over the course of my 5 years of commercial experience in web development I have created projects ranging from marketing landing pages to A/B tests to full scale websites working for both big and small brands along the way.</p>
        
        <p>Aside from coding, some of my other interests include travelling, reading non-fiction books (and occasionally fiction), following a pescatarian lifestyle and being active.</p>
        
        <p>This is my portfolio website in which I upload some of the projects I have worked on. I am constantly looking for opportunities to build my portfolio so if you would like to <Link to="contact">contact me</Link> to talk about a project please do so!</p>
        </div>
        
        <div className="row main-content__box">
        <h4>EXPERIENCE</h4>
        
        <StaticQuery query={cvQuery} render={data =>  {
            return (
                data.allMarkdownRemark.edges.map(({ node }) => (
                    <div className="cv-layout" key={node.id}>
                    <div className="cv-layout__dates">{node.frontmatter.dates}</div>
                    <div className="cv-layout__main-info">
                    <div className="cv-layout__main-info--job-heading">
                    <h6>{node.frontmatter.title}</h6>
                    <span className="cv-layout__main-info--job-location">{node.frontmatter.location}</span>
                    </div>
                    <div className="cv-layout__main-info--job-desc" dangerouslySetInnerHTML={{ __html: node.html }}></div>
                    </div>
                    </div>
                    ))
                    )
                }}
                />
                
                </div>
                </div>
                
                </Layout>)
                
            }
            
const cvQuery = graphql`
    query {
        allMarkdownRemark(filter: {collection: {eq: "cv"}}, sort: {fields: frontmatter___dates, order: DESC}) {
            edges {
                node {
                    id
                    frontmatter {
                        dates
                        location
                        title
                    }
                    html
                }
            }
        }
    }`;
            
export default AboutPage